
  export default {
    props: ['error'],
    layout: 'default',
    data: () => ({
      loaded: false,
      page_title: 'Home | Malayan'
    }),
    methods: {
      initialization ()
      {
        const me = this
        switch (me.error.statusCode)
        {
          case 404:
            me.page_title = 'Page Not Found | Malayan'
            break
          case 403:
            me.page_title = 'Something Went Wrong | Malayan'
            break
          case 401:
            me.page_title = 'Unauthorized | Malayan'
            break
          default:
            me.page_title = 'Something Went Wrong | Malayan'
            break
        }

        setTimeout(() =>
        {
          me.loaded = true
        }, 500)
      }
    },
    mounted ()
    {
      const me = this
      me.initialization()
    },
    head ()
    {
      const me = this

      return {
        title: me.page_title
      }
    }
  }
