export default {
  toggleChildren(state, payload) {
    state.links[payload.unique].show = payload.status
  },
  toggleTopAccordion(state, payload) {
    const me = this
    let target = document.getElementById(
        `Ko0f${payload.unique}${payload.parent.id}6zXs`
      ),
      description = target.querySelector("._k0dr0cCaXS65")

    state.links.forEach((parent, i) => {
      let element = document.getElementById(`Ko0f${i}${parent.id}6zXs`)

      if (payload.parent.id == parent.id) {
        parent.show ^= true
        description.style.height = !parent.show
          ? "0px"
          : `${description.scrollHeight}px`
      } else {
        parent.show = false
        if (element.querySelector("._k0dr0cCaXS65")) {
          element.querySelector("._k0dr0cCaXS65").style.height = "0px"
        }
      }
    })
  },
  accordionTopChecker(state) {
    const me = this
    state.links.forEach((parent, i) => {
      parent.show = false
    })
  },
  UPDATE_CHILD_LINK(state, payload) {
    let data_nav = []
    payload.res.forEach((data, key) => {
      let obj = {
        name: data.name,
        url: `/products/${data.url}`,
        sub: [],
        id: data.sequence,
      }
      data.sub.forEach((sub, i) => {
        let sub_nav = {
          name: sub.name,
          url: `/products/${data.url}/${sub.url}`,
        }
        obj.sub.push(sub_nav)
      })
      data_nav.push(obj)
    })

    data_nav.sort(function (a, b) {
      return a.id - b.id
    })
    state.links[0].children = data_nav
  },
}
