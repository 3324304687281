export default {
  getErrors: (state) => {
    return state.errors
  },
  getStype: (state) => {
    return state.stype
  },
  getHasError: (state) => {
    return state.has_error
  },
}
