// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#css__jCBiZC{background-color:var(--theme_white);margin:0 auto;max-width:85%;padding:12em 0 15em;position:relative;z-index:1}#css__jCBiZC .css__C-qndt{align-items:center;display:flex;flex-flow:column wrap;justify-content:center;text-align:center}#css__jCBiZC .css__C-qndt .css__NGOmq4{height:auto;width:100px}#css__jCBiZC .css__C-qndt .css__0KaNKv{color:var(--theme_primary);font-family:var(--bold);font-size:36px;margin-top:10px;text-transform:uppercase}#css__jCBiZC .css__C-qndt .css__Npa2Ok{color:var(--theme_primary);font-family:var(--light);font-size:24px;margin:10px 0 20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "css__jCBiZC",
	"wrapper": "css__C-qndt",
	"icon": "css__NGOmq4",
	"title": "css__0KaNKv",
	"subtitle": "css__Npa2Ok"
};
module.exports = ___CSS_LOADER_EXPORT___;
