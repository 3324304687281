// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#css__MdYlkf{cursor:pointer;position:absolute;right:50px;top:40px;z-index:2}#css__MdYlkf .css__ouVeIM{height:50px;width:50px}#css__MdYlkf .css__ouVeIM .css__tFrV8L{fill:var(--theme_white)}@media (max-width:1024px) and (min-width:310px){#css__MdYlkf{margin-bottom:30px;position:static;text-align:center}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"back_to_top": "css__MdYlkf",
	"dropdown": "css__ouVeIM",
	"dropdown_a": "css__tFrV8L"
};
module.exports = ___CSS_LOADER_EXPORT___;
