export default {
  populateErrors: (state, payload) => {
    state.errors = []
    payload.items.forEach((item, key) => {
      state.errors.push(item)
    })
    state.has_error = true
    state.stype = payload.stype ? payload.stype : "error"
  },
  hideHasError: (state, payload) => {
    state.errors = []
    state.has_error = payload.status
    state.stype = payload.stype ? payload.stype : "error"
  },
}
