
  import {mapGetters} from "vuex";
  export default {
    watch: {
      $route (route)
      {
        this.checkRouteForCta(route.name);
      },
    },
    components: {
      SpeedkitImage: () => import('nuxt-speedkit/components/SpeedkitImage'),
      BottombarCta: () => import("~/components/global/BottombarCta"),
      BackToTop: () => import("~/components/global/BackToTop"),
    },
    data: () => ({
      loaded: false,
      cta_type: "home",
      back_to_top: false,
    }),
    computed: {
      ...mapGetters({
        populateLinks: "global/bottombar/populateLinks",
      }),
    },
    methods: {
      redirectTo(url){
        if(url !== ""){
            window.location.href = url
        }
      },
      checkRouteForCta (route)
      {
        let result = "home";

        switch (route)
        {
          case "products-slug":
          case "news":
          case "news-slug":
          case "legal-notice":
          case "terms-and-conditions":
          case "privacy-policy":
          case "contact-us":
          case "search":
            result = "none";
            break;
          case "home":
            result = "home";
            break;
          case "claims-and-support-slug":
            result = "claims";
            break;
          case "about-us":
            result = "faq";
            break;
        }

        this.cta_type = result;
      },
      scrollAnimate ()
      {
        let element = document.querySelector(
          `#${ this.attr.bottombar } .${ this.attr.lower }`
        );
        if (element)
        {
          let bounding = element.getBoundingClientRect();
          if (
            bounding.bottom > 0 &&
            bounding.right > 0 &&
            bounding.left <
            (window.innerWidth || document.documentElement.clientWidth) &&
            bounding.top <
            (window.innerHeight || document.documentElement.clientHeight)
          )
          {
            this.back_to_top = true;
          } else
          {
            this.back_to_top = false;
          }
        }
      },
    },
    mounted ()
    {
      setTimeout(() =>
      {
        this.checkRouteForCta(this.$route.name);
        window.addEventListener("scroll", this.scrollAnimate);
        this.loaded = true;
      }, 600);
    },
    destroyed ()
    {
      window.removeEventListener("scroll", this.scrollAnimate);
    },
  };
