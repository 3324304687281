export default {
  userAuthentication(state, payload) {
    state.authenticated = payload.status
  },
  userRegistered(state, payload) {
    state.registered = payload.status
  },
  enteredMobile(state, payload) {
    state.mobile = payload.status
  },
}
