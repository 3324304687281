
	import { mapActions } from 'vuex'

	export default {
		methods: {
			...mapActions({
				backToTop: 'global/settings/backToTop'
			})
		}
	}
