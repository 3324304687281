import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d6acc22c = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _0b000b67 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _228be612 = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _52996b69 = () => interopDefault(import('../pages/home-insurance-philippines.vue' /* webpackChunkName: "pages/home-insurance-philippines" */))
const _18c203e3 = () => interopDefault(import('../pages/index.php.vue' /* webpackChunkName: "pages/index.php" */))
const _23e66211 = () => interopDefault(import('../pages/legal-notices/index.vue' /* webpackChunkName: "pages/legal-notices/index" */))
const _e4e1e1c2 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _86fb3308 = () => interopDefault(import('../pages/pdf/index.vue' /* webpackChunkName: "pages/pdf/index" */))
const _44a61ea6 = () => interopDefault(import('../pages/PDFs/index.vue' /* webpackChunkName: "pages/PDFs/index" */))
const _f4b2adba = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _561133f0 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _16521720 = () => interopDefault(import('../pages/quick-links.vue' /* webpackChunkName: "pages/quick-links" */))
const _1b87ab74 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _777b826a = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _6eb51e80 = () => interopDefault(import('../pages/terms-and-conditions/index.vue' /* webpackChunkName: "pages/terms-and-conditions/index" */))
const _0d3f467e = () => interopDefault(import('../pages/testmode.vue' /* webpackChunkName: "pages/testmode" */))
const _31ef59a4 = () => interopDefault(import('../pages/travel-insurance-philippines.vue' /* webpackChunkName: "pages/travel-insurance-philippines" */))
const _2e4f4305 = () => interopDefault(import('../pages/about/branch-location.vue' /* webpackChunkName: "pages/about/branch-location" */))
const _499b8719 = () => interopDefault(import('../pages/about/history.vue' /* webpackChunkName: "pages/about/history" */))
const _398768cc = () => interopDefault(import('../pages/about/ygc.vue' /* webpackChunkName: "pages/about/ygc" */))
const _7331111a = () => interopDefault(import('../pages/claims-and-support/claims-form/index.vue' /* webpackChunkName: "pages/claims-and-support/claims-form/index" */))
const _155594e3 = () => interopDefault(import('../pages/digital-partners/code.vue' /* webpackChunkName: "pages/digital-partners/code" */))
const _181ad364 = () => interopDefault(import('../pages/search/news/index.vue' /* webpackChunkName: "pages/search/news/index" */))
const _1c85fae6 = () => interopDefault(import('../pages/uploads/annual-report/pdf/_slug/index.vue' /* webpackChunkName: "pages/uploads/annual-report/pdf/_slug/index" */))
const _7dbdb607 = () => interopDefault(import('../pages/about/company-background/_slug/index.vue' /* webpackChunkName: "pages/about/company-background/_slug/index" */))
const _650c6064 = () => interopDefault(import('../pages/news/category/_category_slug/index.vue' /* webpackChunkName: "pages/news/category/_category_slug/index" */))
const _0d2eb684 = () => interopDefault(import('../pages/news/details/_redirect/index.vue' /* webpackChunkName: "pages/news/details/_redirect/index" */))
const _41ebdef5 = () => interopDefault(import('../pages/products/general/_redirect/index.vue' /* webpackChunkName: "pages/products/general/_redirect/index" */))
const _02cbc6c2 = () => interopDefault(import('../pages/products/packaged/_redirect/index.vue' /* webpackChunkName: "pages/products/packaged/_redirect/index" */))
const _3bbea1c8 = () => interopDefault(import('../pages/uploads/brochure/_slug/index.vue' /* webpackChunkName: "pages/uploads/brochure/_slug/index" */))
const _0547c568 = () => interopDefault(import('../pages/uploads/form/_slug/index.vue' /* webpackChunkName: "pages/uploads/form/_slug/index" */))
const _16f97512 = () => interopDefault(import('../pages/uploads/news/_slug/index.vue' /* webpackChunkName: "pages/uploads/news/_slug/index" */))
const _0a0bdf16 = () => interopDefault(import('../pages/about-us/_redirect/index.vue' /* webpackChunkName: "pages/about-us/_redirect/index" */))
const _3b82e16c = () => interopDefault(import('../pages/claims-and-support/_slug/index.vue' /* webpackChunkName: "pages/claims-and-support/_slug/index" */))
const _780be939 = () => interopDefault(import('../pages/news/_news_article/index.vue' /* webpackChunkName: "pages/news/_news_article/index" */))
const _19e6b184 = () => interopDefault(import('../pages/products/_category/index.vue' /* webpackChunkName: "pages/products/_category/index" */))
const _3b742877 = () => interopDefault(import('../pages/products/_category/_product/index.vue' /* webpackChunkName: "pages/products/_category/_product/index" */))
const _40503a10 = () => interopDefault(import('../pages/products/_category/_product/application/index.vue' /* webpackChunkName: "pages/products/_category/_product/application/index" */))
const _6cd580b4 = () => interopDefault(import('../pages/products/_category/_product/application/canceled/index.vue' /* webpackChunkName: "pages/products/_category/_product/application/canceled/index" */))
const _2e748b42 = () => interopDefault(import('../pages/products/_category/_product/application/confirmation/index.vue' /* webpackChunkName: "pages/products/_category/_product/application/confirmation/index" */))
const _c16639e2 = () => interopDefault(import('../pages/products/_category/_product/application/manual-quotation.vue' /* webpackChunkName: "pages/products/_category/_product/application/manual-quotation" */))
const _0405c6d9 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _d6acc22c,
    name: "about-us"
  }, {
    path: "/contact",
    component: _0b000b67,
    name: "contact"
  }, {
    path: "/contact-us",
    component: _228be612,
    name: "contact-us"
  }, {
    path: "/home-insurance-philippines",
    component: _52996b69,
    name: "home-insurance-philippines"
  }, {
    path: "/index.php",
    component: _18c203e3,
    name: "index.php"
  }, {
    path: "/legal-notices",
    component: _23e66211,
    name: "legal-notices"
  }, {
    path: "/news",
    component: _e4e1e1c2,
    name: "news"
  }, {
    path: "/pdf",
    component: _86fb3308,
    name: "pdf"
  }, {
    path: "/PDFs",
    component: _44a61ea6,
    name: "PDFs"
  }, {
    path: "/privacy-policy",
    component: _f4b2adba,
    name: "privacy-policy"
  }, {
    path: "/products",
    component: _561133f0,
    name: "products"
  }, {
    path: "/quick-links",
    component: _16521720,
    name: "quick-links"
  }, {
    path: "/search",
    component: _1b87ab74,
    name: "search"
  }, {
    path: "/services",
    component: _777b826a,
    name: "services"
  }, {
    path: "/terms-and-conditions",
    component: _6eb51e80,
    name: "terms-and-conditions"
  }, {
    path: "/testmode",
    component: _0d3f467e,
    name: "testmode"
  }, {
    path: "/travel-insurance-philippines",
    component: _31ef59a4,
    name: "travel-insurance-philippines"
  }, {
    path: "/about/branch-location",
    component: _2e4f4305,
    name: "about-branch-location"
  }, {
    path: "/about/history",
    component: _499b8719,
    name: "about-history"
  }, {
    path: "/about/ygc",
    component: _398768cc,
    name: "about-ygc"
  }, {
    path: "/claims-and-support/claims-form",
    component: _7331111a,
    name: "claims-and-support-claims-form"
  }, {
    path: "/digital-partners/code",
    component: _155594e3,
    name: "digital-partners-code"
  }, {
    path: "/search/news",
    component: _181ad364,
    name: "search-news"
  }, {
    path: "/uploads/annual-report/pdf/:slug",
    component: _1c85fae6,
    name: "uploads-annual-report-pdf-slug"
  }, {
    path: "/about/company-background/:slug",
    component: _7dbdb607,
    name: "about-company-background-slug"
  }, {
    path: "/news/category/:category_slug",
    component: _650c6064,
    name: "news-category-category_slug"
  }, {
    path: "/news/details/:redirect",
    component: _0d2eb684,
    name: "news-details-redirect"
  }, {
    path: "/products/general/:redirect",
    component: _41ebdef5,
    name: "products-general-redirect"
  }, {
    path: "/products/packaged/:redirect",
    component: _02cbc6c2,
    name: "products-packaged-redirect"
  }, {
    path: "/uploads/brochure/:slug",
    component: _3bbea1c8,
    name: "uploads-brochure-slug"
  }, {
    path: "/uploads/form/:slug",
    component: _0547c568,
    name: "uploads-form-slug"
  }, {
    path: "/uploads/news/:slug",
    component: _16f97512,
    name: "uploads-news-slug"
  }, {
    path: "/about-us/:redirect",
    component: _0a0bdf16,
    name: "about-us-redirect"
  }, {
    path: "/claims-and-support/:slug",
    component: _3b82e16c,
    name: "claims-and-support-slug"
  }, {
    path: "/news/:news_article",
    component: _780be939,
    name: "news-news_article"
  }, {
    path: "/products/:category",
    component: _19e6b184,
    name: "products-category"
  }, {
    path: "/products/:category/:product",
    component: _3b742877,
    name: "products-category-product"
  }, {
    path: "/products/:category/:product/application",
    component: _40503a10,
    name: "products-category-product-application"
  }, {
    path: "/products/:category/:product/application/canceled",
    component: _6cd580b4,
    name: "products-category-product-application-canceled"
  }, {
    path: "/products/:category/:product/application/confirmation",
    component: _2e748b42,
    name: "products-category-product-application-confirmation"
  }, {
    path: "/products/:category/:product/application/manual-quotation",
    component: _c16639e2,
    name: "products-category-product-application-manual-quotation"
  }, {
    path: "/",
    component: _0405c6d9,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
