export default () => ({
  links: [
    {
      id: 1,
      name: "Products",
      url: "/products",
      show: false,
      clickable: true,
      children: [],
    },
    {
      id: 2,
      name: "Claims and Support",
      url: "/claims-and-support",
      show: false,
      clickable: true,
      children: [
        {
          name: "Claims Form",
          url: "/claims-and-support/claims-form",
        },
      ],
    },
    {
      id: 3,
      name: "News",
      url: "/news",
      clickable: true,
    },
    {
      id: 4,
      name: "About Us",
      url: "/about-us",
      show: false,
      clickable: true,
    },
    {
      id: 5,
      name: "Contact Us",
      url: "/contact-us",
      clickable: true,
    },
  ],
})
