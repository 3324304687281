export default {
  getAuthenticated: (state) => {
    return state.authenticated
  },
  getRegistered: (state) => {
    return state.registered
  },
  getMobile: (state) => {
    return state.mobile
  },
}
