import Vue from "vue"
import { mapGetters, mapMutations } from "vuex"

Vue.mixin({
  computed: {
    ...mapGetters({
      mobile: "global/settings/getMobile",
    }),
  },
  methods: {
    ...mapMutations({
      toggleModalStatus: "global/modal/toggleModalStatus",
    }),
    animateElements(page, targets) {
      let observer = new IntersectionObserver((items) => {
        items.forEach((item, key) => {
          if (item.isIntersecting) {
            item.target.classList.add(page.attr.viewport);
            observer.unobserve(item.target);
          }
        });
      });
      targets.forEach((target, key) => {
        let elements = document.querySelectorAll(target);
        elements.forEach((element, k) => {
          observer.observe(element);
        });
      });
    },
    niceBytes(size) {
      const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let l = 0,
        n = parseInt(size, 10) || 0;

      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
    },
    checkValue(event) {
      const me = this;
      let target = event.target;
      if (target.value.length > 0) {
        target.classList.add("filled");
      } else {
        target.classList.remove("filled");
      }
    },
    parseInputToDate(target) {
      const me = this;
      let lastValue = "";
      let value = target.split("-").join("");
      if (value.length > 0) {
        value = value.match(new RegExp(".{1,4}", "g")).join("-");
        let valueArrayChecker = value.split("-");
        if (valueArrayChecker[1]) {
          if (valueArrayChecker[1].length > 0) {
            value = value.split("-");
            lastValue = `${value[0]}-`;
            lastValue += value[1].match(new RegExp(".{1,2}", "g")).join("-");
          }
        } else {
          lastValue = value;
        }
      }
      return lastValue;
    },
    sharer(type, link = null, image = null) {
      if (link == null) {
        link = window.location.href;
      }

      let isMobile = window.innerWidth <= 768 ? true : false;
      let shareLink = null;

      switch (type) {
        case "facebook":
          shareLink = `https://${
            isMobile ? "m" : "www"
          }.facebook.com/sharer/sharer.php?u=${link}`;
          break;
        case "twitter":
          shareLink = `https://${
            isMobile ? "m" : "www"
          }.twitter.com/share?url=${link}`;
          break;
        case "linkedn":
          shareLink = `http://www.linkedin.com/shareArticle?mini=true&url=${link}&title=I wanted to share this great website with you`;
          break;
        case "whatsapp":
          shareLink = `https://api.whatsapp.com/send?text=I%20wanted%20to%20share%20this%20great%20website%20with%20you%20${link}`;
          break;
        case "telegram":
          shareLink = `https://t.me/share/url?url=${link}&text=I wanted to share this great website with you`;
          break;
        case "pinterest":
          shareLink = `https://pinterest.com/pin/create/link/?url=${link}&media=${image}&description=I wanted to share this great website with you`;
          break;
        case "email":
          shareLink = `mailto:?subject=I wanted to share this great website with you&body=Check out this site ${link}.`;
          break;
      }
      if (type != "email") {
        window.open(shareLink, "shareWindow", "status=1,width=600,height=450");
      } else {
        window.location.href = shareLink;
      }
    },
    randomString() {
      return Math.random().toString(36).substring(2);
    },
    totalItems(value) {
      if (value != 0) {
        return parseFloat(value)
          .toFixed()
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    totalCount(value) {
      let count = 0;
      if (value) {
        count = parseFloat(value)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return count;
    },
    toJSON(data) {
      if (data) {
        return JSON.parse(JSON.stringify(Object.fromEntries(data)));
      }
    },
    parser(data) {
      if (data) {
        return JSON.parse(data);
      }
    },
    replacer(data) {
      if (data) {
        return data.replace(/\-/g, " ");
      }
    },
    convertToSlug(data) {
      let slug = "";
      if (
        data.match(/[!@#$%^&*(){}:"’'<>?,./|“”]|\`|\~|\=|\-|\+|\_|\[|\]|\\/g)
      ) {
        slug = data
          .toLowerCase()
          .replace(
            /[!@#$%^&*(){}:"'’<>?,./|“”]|\`|\~|\=|\-|\+|\_|\[|\]|\\/g,
            ""
          )
          .replace(/\s/g, "-");
      } else {
        slug = data.toLowerCase().replace(/\s/g, "-");
      }
      return slug;
    },
    currencyToDouble(value) {
      let temp = value.replace(/[^0-9.-]+/g, "");
      return parseFloat(temp);
    },
    birthDateChecker(value, yearLimit, yearStart) {
      let givenDate = this.parseInputToDate(value);
      let now = this.$moment(),
        birthDate = this.$moment(givenDate),
        age = now.diff(birthDate, "years", false);
      if (age >= yearStart && age <= yearLimit) {
        return true;
      } else {
        return false;
      }
    },
    // Date checker please check
    birthDateTravelerChecker(value1, value2, yearLimit, yearStart) {
      if (
        !this.$moment(value1, "YYYY-MM-DD").isValid() ||
        !this.$moment(value2, "YYYY-MM-DD").isValid()
      ) {
        return false;
      }
      let birthdate = this.$moment(value1, 'YYYY-MM-DD');
      let referenceDate = this.$moment(value2, 'YYYY-MM-DD');
      let ageInYears = referenceDate.diff(birthdate, "years", false);

      // console.log("Year Start", yearStart )
      // console.log("Year Limit", yearLimit )
      // console.log('Birthdate:', birthdate.format('YYYY-MM-DD'));
      // console.log('Reference Date:', referenceDate.format('YYYY-MM-DD'));
      // console.log('Age in Years:', ageInYears);
      // console.log('Birth Month:', birthdate.month() + 1)
      // console.log('Birth Date:', birthdate.date())
      // console.log('Ref Month:', referenceDate.month() + 1)
      // console.log('Ref Date:', referenceDate.date())

      if (ageInYears > yearStart && ageInYears <= yearLimit) {
        // console.log("Birthdate passed validation!")
        return true;
      }
      else
      {
        if (ageInYears == yearLimit + 1) {
          // console.log("Passed validation condition A")
          if (
            birthdate.month() + 1 > referenceDate.month() + 1 &&
            birthdate.date() == referenceDate.date() //
          ) {
            return true;
          } else if (
            birthdate.month() + 1 < referenceDate.month() + 1 &&
            birthdate.date() == referenceDate.date()
          ) {
            return false;
          } else if (
            birthdate.month() + 1 == referenceDate.month() + 1 &&
            birthdate.date() == referenceDate.date()
          ) {
            return false;
          } else if (
            birthdate.month() + 1 == referenceDate.month() + 1 &&
            birthdate.date() < referenceDate.date()
          ) {
            return false;
          } else if (
            birthdate.month() + 1 == referenceDate.month() + 1 &&
            birthdate.date() > referenceDate.date()
          ) {
            return true;
          }
        }
        if (ageInYears == yearStart) {
          // console.log("Passed validate condition B")
          if (
            birthdate.month() + 1 > referenceDate.month() + 1 &&
            birthdate.date() == referenceDate.date()
          ) {
            return false;
          } else if (
            birthdate.month() + 1 < referenceDate.month() + 1 &&
            birthdate.date() == referenceDate.date()
          ) {
            return true;
          } else if (
            birthdate.month() + 1 == referenceDate.month() + 1 &&
            birthdate.date() == referenceDate.date()
          ) {
            return true;
          } else if (
            birthdate.month() + 1 == referenceDate.month() + 1 &&
            birthdate.date() < referenceDate.date()
          ) {
            return true;
          } else if (
            birthdate.month() + 1 == referenceDate.month() + 1 &&
            birthdate.date() > referenceDate.date()
          ) {
            // console.log("condition doesnt exist!")
            return false;
          }
          return true;
        }

      }
      return false;
    },
    birthDateDaysChecker(value, daysLimit, daysStart) {
      let givenDate = this.parseInputToDate(value);
      let now = this.$moment(),
        birthDate = this.$moment(givenDate),
        ageInDays = now.diff(birthDate, "days", false);
      if (ageInDays >= daysStart && ageInDays <= daysLimit) {
        return true;
      } else {
        return false;
      }
    },
    dateConverter(value) {
      let givenDate = this.parseInputToDate(value),
        today = new Date(),
        currentDate =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate(),
        day1 = new Date(givenDate),
        day2 = new Date(currentDate),
        diff = Math.abs(day2 - day1),
        days = diff / (1000 * 3600 * 24),
        currentAge = parseInt(days / 365);
      return currentAge;
    },
    yearConverter(value) {
      let givenYear = value,
        today = new Date(),
        currentYear = today.getFullYear(),
        diff = currentYear - givenYear;
      return diff;
    },
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "" : str;
    },
    uppercaseOnKeyup(type, event, spaceBetween = false) {
      let input = event.target.value;
      if (spaceBetween == true) {
        this.form_data[type] = input.toUpperCase();
      } else {
        this.form_data[type] = input
          .replace(/[^a-zA-Z0-9]/g, "")
          .trim()
          .toUpperCase();
      }
      return;
    },
    lowercaseOnKeyup(type, event, spaceBetween = false) {
      let input = event.target.value;
      if (spaceBetween == true) {
        this.form_data[type] = input.toLowerCase();
      } else {
        this.form_data[type] = input
          .replace(/[^a-zA-Z0-9]/g, "")
          .trim()
          .toLowerCase();
      }
      return;
    },
    emailFormatOnKeyup(type, event) {
      let input = event.target.value;
      this.form_data[type] = input.trim().toLowerCase();
      return;
    },
    numberFormatOnKeyup(type, event) {
      let input = event.target.value;
      this.form_data[type] = input.replace(/[^0-9]/g, "").trim();
      return;
    },
    urlFormatter(url) {
      return new URL(url).pathname;
    },
    onLoadImgLazy() {
      let lazyImages = document.querySelectorAll("img[data-src]");
    },
    websiteChecker(items, page) {
      items.map((item) => {
        if (!sessionStorage.getItem(`_${item}_`)) {
          page.toggle[item] = true;
        } else {
          if (`_${item}_expiration_`) {
            let agreed_date = this.$moment(
                sessionStorage.getItem(`_${item}_expiration_`)
              ).add(item == "ads" ? 1 : 7, "days"),
              expiration_date = this.$moment();
            if (agreed_date.diff(expiration_date) <= 0) {
              sessionStorage.removeItem(`_${item}_`);
              sessionStorage.removeItem(`_${item}_expiration_`);
              page.toggle[item] = true;
            }
          }
        }
      });
    },
    changeSelectedPlanModal() {
      this.modal ^= true;
      this.subtitle = `Opps!, the plan you choose doesn't cover the entered age.`;
      this.description = "Would you like to change your plan?";
      this.button_action = "vital-cover";
    },
    stayOnCurrentPlan() {
      this.form_data.date_of_birth = "";
      this.modal ^= true;
    },
    getVitalCoverPlans() {
      this.modal ^= true;
      this.yearError = "";
      let ages = ["1|64", "65|75"];
      let packaged = {
        coverage: "annual",
        age: "",
        lock: true,
        lock_age: "1|64",
      };
      ages.forEach((age, key) => {
        if (this.age_plan != age) {
          packaged.age = age;
          packaged.lock_age = this.age_plan;
        }
      });
      setTimeout(() => {
        this.$nuxt.$emit("reinitialized-vital-packaged", packaged);
      }, 500);
    },
  },
});
