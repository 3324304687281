
  export default {
    props: {
      label: {
        type: String,
        default: ''
      },
      link: {
        type: String,
        default: '#'//null
      },
      color: {
        type: String,
        default: 'primary'
      },
      link_status: {
        type: String/Number,
        default: 0
      },
      inline: {
        type: Boolean,
        default: false
      },
      has_margin_left: {
        type: Boolean,
        default: false
      },
      minimal: {
        type: Boolean,
        default: false
      },
      divided: {
        type: String,
        default: 'one'
      }
    }
  }
