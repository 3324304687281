
  import SecureLS from "secure-ls";
  import {mapGetters, mapMutations} from "vuex";
  export default {
    components: {
      SpeedkitImage: () => import('nuxt-speedkit/components/SpeedkitImage'),
    },
    data: () => ({
      loaded: false,
      testmode: false,
      toggle: {
        burger: false,
      },
      search: "",
      children: [],
      to_return: [],
    }),
    watch: {
      $route (to, from)
      {
        this.toggle.burger = false;
        document.body.classList.remove("no_scroll");
      },
    },
    computed: {
      ...mapGetters({
        populateLinks: "global/topbar/populateLinks",
      }),
    },
    methods: {
      ...mapMutations({
        toggleChildren: "global/topbar/toggleChildren",
        toggleTopAccordion: "global/topbar/toggleTopAccordion",
        UPDATE_CHILD_LINK: "global/topbar/UPDATE_CHILD_LINK",
      }),
      submit ()
      {
        this.$router.push(`/search?sort_by=date_desc&keyword=${ this.search }`);
      },
      toggleStatus (type, close)
      {
        if (!close)
        {
          this.toggle[type] ^= true;
          document.body.classList.add("no_scroll");
        } else
        {
          this.toggle[type] = false;
          document.body.classList.remove("no_scroll");
        }
      },
      getChildrenData ()
      {
        const child = this.$axios
          .$get("web/products-topbar-nav")
          .then((res) =>
          {
            this.UPDATE_CHILD_LINK(res);
          })
          .catch((err) =>
          {
            this.$nuxt.error({
              statusCode: 500,
              message: "Something Went Wrong",
            });
          });
      },
    },
    mounted ()
    {
      let ls = new SecureLS();
      this.testmode = ls.get("__testmode__");

      setTimeout(() =>
      {
        this.loaded = true;
        this.getChildrenData();
      }, 300);
    },
  };
