export default {
  toggleModalStatus: (state, payload) => {
    state.show[payload.type] = payload.status
    if (!payload.status) {
      document.body.classList.remove("no_scroll")
    } else {
      if (payload.type != "loader") document.body.classList.add("no_scroll")
    }
  },
}
