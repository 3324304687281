export default async function ({ app, redirect, route, error, store }) {

    switch (route.name) {
        case 'claims-and-support-slug':
            if (!route.params.slug) {
                redirect('/claims-and-support/claim-process')
            }
            break
    }

    switch (route.path) {
        case '/claims-and-support':
        case '/claims-and-support/':
                redirect('/claims-and-support/claim-process')
            break
    }
}
